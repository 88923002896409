<template src="./index.html"> </template>

<script>
import { mapState, mapActions } from 'vuex'
import Banner from '_c/banner/Index'
import MainListV2 from '_c/mainlistv2/Index'
import CategorysBoxV2 from '_c/CategorysBoxV2'
import ContactTip from '_c/ContactTip'

const SHORTKEY_MODULE_TYPE = 'tid'
const SHORTKEY_SUB_TYPE = 'stid'

export default {
  async asyncData ({ store, route }) {
    const PAGE = store.state.commonData.pageConfig['provider']
    const DISPATCH = store.dispatch
    const QUERY = store.state.route.query
    const CURRENTPAGE = store.state.route.name

    return await Promise.all([
      DISPATCH('componentCategorys/setTypeId',{
        type: 'serviceTypeId',
        id: +QUERY[SHORTKEY_MODULE_TYPE] || 0
      }),
      DISPATCH('componentCategorys/setTypeId',{
        type: 'sceneTypeId',
        id: +QUERY[SHORTKEY_SUB_TYPE]
      }),
      DISPATCH('commonData/setCurrentPage', CURRENTPAGE),
      DISPATCH('componentCategorys/getCategorysV2'),
      DISPATCH('dataProvider/getBestProviderList', {
        offset: 0,
        limit: PAGE.chosenLimit
      }),
      DISPATCH('dataProvider/getProviderList', {
        offset: 0,
        limit: PAGE.mainLimit,
        key: QUERY.search || '',
        serviceType : +QUERY[SHORTKEY_MODULE_TYPE] || 0,
        sceneType : +QUERY[SHORTKEY_SUB_TYPE] || 0
      })
    ])
  },
  components:{
    Banner,
    MainListV2,
    CategorysBoxV2,
    ContactTip,
  },
  computed: {
    ...mapState('componentCategorys', {
      serviceTypeList: s => s.serviceTypeList || [],
      sceneTypeList: s => s.sceneTypeList || []
    }),
    dataFields() {
      let obj = [
        {
          field: 'serviceTypeId',
          shortField: SHORTKEY_MODULE_TYPE,
          name: '服务类型',
          options: this.serviceTypeList
        },
        {
          field: 'sceneTypeId',
          shortField: SHORTKEY_SUB_TYPE,
          name: '应用场景',
          options: this.sceneTypeList
        }
      ]
      return obj
    }
  }
}
</script>
